import React from 'react'
import './CommonModal.scss';
import { Modal } from 'react-bootstrap';

const CommonModal = ({ show, handleClose, className, heading, children }) => {
    return (
        <Modal
            show={show}
            onHide={handleClose}
            centered
            className={`${className || ""} common_modal`}
        >
            {
                heading &&
                <Modal.Header>
                    <Modal.Title>{heading}</Modal.Title>
                </Modal.Header>
            }
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default CommonModal
