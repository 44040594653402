import { Routes, Route } from "react-router-dom"
import ComingSoon from "./components/Pages/Public/ComingSoon/ComingSoon";

function App() {
  return (
    <Routes>
      <Route>
        <Route path="/" element={<ComingSoon />} />
      </Route>
    </Routes>
  );
}

export default App;
