import React from 'react'
import './SuccessModal.scss';
import CommonModal from '../CommonModal/CommonModal';
import successAnimation from '../../../../Assets/animations/success-animation.json';
import successTickAnimation from '../../../../Assets/animations/success-tick-animation.json';
import Lottie from 'lottie-react'

const SuccessModal = ({ show, handeClose }) => {
    return (
        <CommonModal
            show={show}
            className="success_modal"
            handleClose={handeClose}
        >
            <Lottie loop={false} className='success_animation' animationData={successAnimation} />
            <div className="success_content">
                <Lottie className='success_tick_animation' loop={true} animationData={successTickAnimation} />
                <p>Email has sent successfully</p>
            </div>
        </CommonModal>
    )
}

export default SuccessModal
