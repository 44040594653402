import { ErrorMessage, Field } from 'formik'
import ErrorMsg from '../ErrorMsg/ErrorMsg'
import './Input.scss';
import React from 'react'

const Input = ({ label, leftIcon, rightIcon, className, name, ...rest }) => {
    return (
        <div className={`input_style ${className || ""}`}>
            {
                label &&
                <label htmlFor={name}>{label}</label>
            }
            <div className="input_style_inner">
                {
                    leftIcon &&
                    <span>{leftIcon}</span>
                }
                <Field
                    {...rest}
                    className={`${leftIcon ? 'left_icon_input' : ''} ${rightIcon ? 'right_icon_input' : ''}`}
                    name={name}
                />
                {
                    rightIcon && <span>{rightIcon}</span>
                }
            </div>
            <ErrorMessage
                name={name}
                component={ErrorMsg}
            />
        </div>
    )
}

export default Input
