import React, { useRef, useState } from "react";
import "./ComingSoon.scss";
import emailjs from "@emailjs/browser";
import {
  EmailIcon,
  FacebookIcon,
  InstagramIcon,
  LogoIcon,
  NavigateIcon,
  PhoneIcon,
  UserIcon,
  //   WhatsappIcon,
} from "../../../../Assets/SvgIcons/SvgIcons";
import { Link } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Input from "../../../common/Form/Input/Input";
import { Col, Container, Row } from "react-bootstrap";
import Button from "../../../common/Button/Button";
import SuccessModal from "../../../common/Modals/SuccessModal/SuccessModal";
import { toast } from "react-hot-toast";
const ComingSoon = () => {
  const form = useRef();
  const initialValues = {
    name: "",
    phone: "",
    email: "",
    msg: "",
  };
  const validationSchema = Yup.object({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email("Invalid Email"),
    phone: Yup.number().required("Required"),
    msg: Yup.string().required("Required"),
  });
  const handleSubmit = (values, onSubmittingProps) => {
    emailjs
      .sendForm(
        "lovepreetboouk",
        "emailToUser",
        form.current,
        "0H22yEqGy_1IjPfuv"
      )
      .then(
        (result) => {
          toast.success(result.text);
        },
        (error) => {
          toast.error(error.text);
        }
      );
    emailjs
      .sendForm(
        "lovepreetboouk",
        "emailToAdmin",
        form.current,
        "0H22yEqGy_1IjPfuv"
      )
      .then(
        (result) => {
          onSubmittingProps.resetForm();
          onSubmittingProps.setSubmitting(false);
          setShow(true);
        },
        (error) => {
          toast.error(error.text);
          onSubmittingProps.resetForm();
          onSubmittingProps.setSubmitting(false);
        }
      );
  };

  const [show, setShow] = useState(false);
  return (
    <>
      <section className="coming_soon">
        <Container fluid>
          <div className="coming_soon_card">
            <div className="card_inner">
              <div className="header">
                <Link to="">
                  <LogoIcon />
                </Link>
                <p className="tag_text my-5 fw-bold">
                  See it from every angle with 360 Accounting
                </p>
              </div>
              <div className="coming_soon_inner">
                <div className="coming_soon_text">
                  {/* <h1>Coming Soon</h1>
                  <h3>360 Accounting Inc.</h3> */}

                  <h2 className="mt-lg-5 mt-sm-4 my-4">Why Us?</h2>
                  <p>
                    We go the extra mile to ensure that your business receives
                    the precise and accurate care that it deserves. With our due
                    diligence and personalize bookkeeping you have a competitive
                    edge over others. Our objective is to take care of all your
                    accounting needs so that you can solely focus on growing
                    your business. We are experienced in handling books for
                    various industries such as trucking, construction, retail,
                    restaurants, dental, childcare, insurance management, and
                    sole proprietorship to name a few.
                  </p>
                  <h2 className="mt-lg-5 mt-sm-4 mt-3">Services</h2>
                  <div className="text-md-start">
                    <h3>Full Cycle Bookkeeping:</h3>
                    <p>
                      Accounts receivable, Accounts payable, invoicing, tracking
                      expenses, verifying sub-contractors, filing T5018’s,
                      helping with CRA audits and communications.
                    </p>
                    <h3>Payroll:</h3>
                    <p>
                      We work with Payworks, ADP, Wagepoint, QuickBooks and
                      more. We are also experienced in filing record of
                      employments, T4s and T4Sums at year end.
                    </p>
                    <h3>Indirect Taxes, & Other Government Filings:</h3>
                    <p>
                      We specialize in filling GST, HST, PST, WCB, EHT, applying
                      EI and more.
                    </p>
                    <h3>Cash Flow & Budget Analysis:</h3>
                    <p>
                      We can help in cash flow management to ensure your
                      business runs smoothly and do forecasts, budgeting as per
                      your business needs.
                    </p>
                    <h3>Analytical Services:</h3>
                    <p>
                      We provide job costing services that can help you track
                      costs related to individual jobs for your business. We
                      provide custom, monthly, quarterly, and annual reports as
                      per your business needs.
                    </p>
                  </div>
                  <p className="tag_text fw-bold my-5">
                    Let 360 be your one stop shop for all your bookkeeping
                    needs!!!
                  </p>
                </div>
                <h2>Contact Us</h2>
                <Formik
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={handleSubmit}
                >
                  {(formik) => (
                    <Form ref={form}>
                      <Row className="justify-content-end">
                        <Col md={6}>
                          <Row>
                            <Col xs={12}>
                              <Input
                                label="Name"
                                name="name"
                                placeholder="Business Name"
                                leftIcon={<UserIcon />}
                              />
                            </Col>
                            <Col xs={12}>
                              <Input
                                label="Email"
                                name="email"
                                placeholder="Your Email"
                                leftIcon={<EmailIcon />}
                              />
                            </Col>
                            <Col xs={12}>
                              <Input
                                type="number"
                                label="Phone"
                                name="phone"
                                placeholder="XXXXXXXXXX"
                                leftIcon={<PhoneIcon />}
                              />
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6} className="textarea_box">
                          <Input
                            label="How can we help you?"
                            className="textarea_input"
                            as="textarea"
                            name="msg"
                            placeholder="Enter your message"
                          />
                        </Col>
                        <Col md={6}>
                          <Button
                            type="submit"
                            fluid="true"
                            rightIcon={<NavigateIcon />}
                            title="Submit"
                            loading={formik.isSubmitting}
                          />
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
                <SuccessModal show={show} handeClose={() => setShow(false)} />
              </div>
              <footer>
                <div className="footer_inner">
                  <div className="footer_logo">
                    <LogoIcon />
                  </div>
                  <div className="footer_navigation">
                    <ul className="footer_social">
                      <li>
                        <a href="https://instagram.com/360accountinginc?igshid=MWQ1ZGUxMzBkMA==">
                          <InstagramIcon />
                        </a>
                      </li>
                      {/* <li>
                        <a href="/">
                          <WhatsappIcon />
                        </a>
                      </li> */}
                      <li>
                        <a href="https://m.facebook.com/people/360-Accounting-Inc/100094017993288/">
                          <FacebookIcon />
                        </a>
                      </li>
                    </ul>
                    <ul className="footer_personal">
                      <li>
                        <a href="tel:+1 778 847 2805">
                          <PhoneIcon /> +1 778 847 2805
                        </a>
                      </li>
                      <li>
                        <a href="/">
                          <EmailIcon />
                          info@360accountinginc.com
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="footer_copyright">
                  <p>360accounting © 2023 All rights reserved</p>
                </div>
              </footer>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default ComingSoon;
