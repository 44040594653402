import React from 'react'
import './ErrorMsg.scss'

const ErrorMsg = ({ children }) => {
    return (
        <p className='error_msg'>{children}</p>
    )
}

export default ErrorMsg
