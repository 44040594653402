import React from 'react';
import './Button.scss';
import { Spinner } from 'react-bootstrap';

const Button = ({ className, loading, disabled, type, rightIcon, fluid, title, ...rest }) => {
    console.log(loading);
    return (
        <button
            {...rest}
            type={type || 'button'}
            className={`btn_style ${className || ""} ${fluid ? 'w-100' : ''}`}
            disabled={loading || disabled}
        >
            {
                loading ?
                    <>
                        <Spinner variant='white' />
                    </>
                    :
                    <>
                        {
                            title
                        }
                        {
                            rightIcon &&
                            <span span className="right_icon">
                                {rightIcon}
                            </span>
                        }
                    </>
            }
        </button >
    )
}

export default Button;

